import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { configuration_fe } from '../configurations/infocamere-config';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private CONFIGURATION: any;

  getConfiguration() {
    if (!this.CONFIGURATION)
      if (environment.configuration == 'info')
        this.CONFIGURATION = configuration_fe;
    return this.CONFIGURATION
  }

}
