<div class="wrapper wrapper-full-page" style="background-color: rgb(255, 255, 255);">

  <!-- TOP PART -->
  <div class="header">
    <div class="title"></div>
    <mat-form-field>
      <mat-select [(value)]="selectedPagina" placeholder="SELEZIONA LA TUA PROVINCIA">
        <mat-option value="torino"> TORINO </mat-option>
        <mat-option value="cuneo"> CUNEO </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="btn-enter" (click)="redirectToLogin()" [disabled]="!selectedPagina">Entra</button>
  </div>

  <div class="col-12 text-center text-justify">
    <img src="./assets/img/landing_bottom-bg2.jpg" class="img-fluid" />
  </div>

  <div>
    <div class="container">
      <div style="background-image: url('./assets/img/logo2.png'); " id="img1">
        <div>
          <div>
            <div style="display: flex;">
              <div>
                <div style="display: flex; flex-direction: column;" class="text-center text-justify">
                  <div class="col-6 offset-3" style="padding-top: 20px; align-items: center;">
                    <img src="./assets/img/logo_landing2.png" class="img-fluid" />
                  </div>
                  <h3 id="testo1">
                    Scopri la piattaforma che offre a commercianti e artigiani
                    servizi, strumenti e formazione per crescere insieme anche on line.
                    Aumenta le tue competenze digitali e sfrutta al meglio le opportunità
                    offerte dal web, dai social media e dalle innovazioni tecnologiche.
                  </h3>

                  <h3 id="testo1" style="font-size: 28px">
                    <strong>
                      Entra anche tu nella Community! È facile e gratuito.
                    </strong>
                  </h3>
                  <h4 style="color:#213067">
                    La piattaforma è attiva nelle <br>
                    <strong style="font-size: 20px"> province di Torino e di Cuneo</strong>
                  </h4>
                  <h3>
                  </h3>

                </div>
              </div>
            </div>
            <div class="text-center text-justify" style="max-width: 100%; margin: 0 auto; float: none;">
              <br>
              <div class="blue-background">
                <h3 style="font-weight: bold; font-size: 250%; font-size: 4em; margin-bottom: 0px; margin-top: 0.1%">
                  <strong>
                    Cosa trovi nella piattaforma
                  </strong>
                </h3>
                <div style="display: flex; justify-content: center;" class="row" id="anchor">
                  <div class="blue" class="row" style="width: 60%;">

                    <div style="display: table-cell; " class="col-sm-12 col-md-4">
                      <a (click)="onClick('pillole')">
                        <img src="./assets/img/info/logo-corsi.png" class="body-icon">
                      </a>
                      <h3 style="line-height: 25px; font-size: 1.7em;">Pillole<br> Formative</h3>
                    </div>

                    <div style="display: table-cell;" class="col-sm-12 col-md-4">
                      <a (click)="onClick('accompagnamento')">
                        <img src="./assets/img/info/logo-servizi.png" class="body-icon">
                      </a>
                      <h3 style="line-height: 25px; font-size: 1.7em;">Accompagnamento<br> al digitale</h3>
                    </div>

                    <div style="display: table-cell;" class="col-sm-12 col-md-4">
                      <a (click)="onClick('esperienze')">
                        <img src="./assets/img/people-icon.png" class="body-icon">
                        <h3 style="line-height: 25px; font-size: 1.7em;">Esperienze<br> in digitale</h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center text-justify" style="margin: 0 auto; float: none;">
            <br>
            <div class="wrapper" style="display: flex; justify-content: center; gap: 2px;">
              <mat-form-field>
                <mat-select [(value)]="selectedPagina" placeholder="SELEZIONA LA TUA PROVINCIA">
                  <mat-option value="torino"> TORINO </mat-option>
                  <mat-option value="cuneo"> CUNEO </mat-option>
                </mat-select>
              </mat-form-field>
              <button class="btn-enter" (click)="redirectToLogin()" [disabled]="!selectedPagina">Entra</button>
            </div>

            <div class="orange-background">
              <h3 id="pillole" style="font-size: 3em; font-weight: bold; margin-top: 0px;">
                <strong>
                  Pillole formative
                </strong>
              </h3>
              <div id="testo2">
                Video pillole e podcast per comprendere meglio il mondo della comunicazione digitale. Troverai
                argomenti e approfondimenti sul mondo del web, su Google, sui Social e su tutte le innovazioni
                tecnologiche, per essere sempre aggiornato in tempo reale.
              </div>
              <div>
                <video #videoEl controls controlsList="nodownload nofullscreen noremoteplayback"
                  class="embed-responsive-item" [src]="videoSource2" playsinline
                  class="col-sm-12 col-md-5 video1"></video>
              </div>
            </div>
            <div>
              <div>
                <a id="accompagnamento">
                  <h3 style=" font-weight: bold; color:#F47921; display: flex; align-items:
                      center; justify-content: center; margin-top: 0px; font-size: 3em;">
                    Accompagnamento al digitale</h3>
                </a>
                <div id="testo3">
                  Servizi di supporto gratuiti messi a disposizione dal Punto Impresa Digitale (PID) della Camera di
                  commercio di Torino e dalle Associazioni di Categoria, per accompagnarti nel tuo percorso di
                  digitalizzazione. Troverai tutti i servizi disponibili da subito e riceverai suggerimenti su quali
                  siano più indicati in base al tuo posizionamento e alle tue esigenze. Ecco un esempio!
                </div>
                <div class="row" id="testo4">
                  <div class="col-sm-12 col-md-6">
                    <img style="width: 100%;" src="./assets/img/digitale.png">
                  </div>
                  <div style="text-align: left; " class="col-sm-12 col-md-6">
                    <div id="palestra">
                      Una palestra sul digitale per chi è nella community
                    </div>
                    <div style="font-size: 1.20em;">
                      Per gli iscritti a #digitalesottocasa è nato un
                      percorso dedicato della palestra digitale
                      proposta gratuitamente dal PID della Camera di commercio di Torino. Si tratta di allenamenti on
                      line, in pausa pranzo, dedicati a Google my Business, Facebook, Instagram, Whatsapp & Messenger e
                      molti altri argomenti sul marketing digitale.
                    </div>
                  </div>
                </div>
              </div>

              <div style="background-color: #ff9c54; color:white;">
                <h3 id="esperienze">Esperienze in digitale</h3>
                <div id="testo5">
                  In questa sezione ti raccontiamo le storie di commercianti e artigiani come te che hanno
                  intrapreso
                  il
                  percorso digitale. Sono belle storie, storie di noi, storie virtuose, storie da prendere ad
                  esempio.
                </div>

                <div
                  style="display: flex; justify-content: center; margin: 0 auto; float: none; margin-bottom: 3%; padding-bottom: 2%;"
                  class="row">

                  <div style="justify-content: left; padding: 1%; padding-left: 1%; " class="col-sm-12 col-md-6">
                    <video #videoEl controls controlsList="nodownload nofullscreen noremoteplayback"
                      class="embed-responsive-item" [src]="videoSource5" playsinline
                      style="width: 80%; height: 95%; margin-bottom: 2%;"></video>
                  </div>

                  <div style="justify-content: right; padding: 1%; padding-right: 1% " class="col-sm-12 col-md-6">
                    <video #videoEl controls controlsList="nodownload nofullscreen noremoteplayback"
                      class="embed-responsive-item" [src]="videoSource4" playsinline
                      style="width: 80%; height: 95%; margin-bottom: 2%;"></video>
                  </div>
                </div>
              </div>
            
              
              <div style="margin-bottom: 20px;">
                <h3 style="font-weight: bold; font-size: 250%; font-size: 4em; margin-bottom: 0px; margin-top: 0.1%; color:#213067; margin-bottom: 20px;">
                  <strong>
                    I nostri Social Network
                  </strong>
                </h3>
                <div style="display: flex; justify-content: center;" class="row" id="anchor">
                  <div class="blue" class="row" style="width: 60%;">

                    <div style="display: table; margin: 0 auto;">
                      <div style="display: table-cell; text-align: center;" class="col-sm-12 col-md-4">
                        <a href="https://www.facebook.com/digitalesottocasa/" target="_blank">
                          <img src=".\assets\img\facebook.png" class="body-icon2">
                        </a>
                        <h3 style="line-height: 25px; font-size: 1.7em;">Facebook</h3>
                      </div>
                    
                      <div style="width: 100px;"></div> <!-- Spazio vuoto -->
                    
                      <div style="display: table-cell; text-align: center;" class="col-sm-12 col-md-4">
                        <a href="https://www.instagram.com/digitalesottocasa.it/" target="_blank">
                          <img src=".\assets\img\instagram.png" class="body-icon2">
                        </a>
                        <h3 style="line-height: 25px; font-size: 1.7em;">Instagram</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              


            </div>
          </div>
        </div>
      </div>
      <!-- FOOTER -->
      <div class="footer-container">
        <div class="container">
          <div class="row footer">
            <div class="col-10 offset-1">
              <img src="./assets/img/info/footer_info.png" class="img-fluid" />
            </div>
          </div>
          <div class="row informativa">
            <div class="col-12">
              <a [hidden]="!(CONFIG.legalLinks && CONFIG.legalLinks.privacyPolicy)"
                href="{{CONFIG.legalLinks.privacyPolicy}}" target="_blank" class="informativaLink">Privacy policy</a>
              &nbsp;
              <a [hidden]="!(CONFIG.legalLinks && CONFIG.legalLinks.cookiePolicy)"
                href="{{CONFIG.legalLinks.cookiePolicy}}" target="_blank" class="informativaLink">Cookie policy</a>
              &nbsp;
              <a [hidden]="!(CONFIG.legalLinks && CONFIG.legalLinks.socialPolicy)"
                href="{{CONFIG.legalLinks.socialPolicy}}" target="_blank" class="informativaLink">Social media
                policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>