import { NgModule } from '@angular/core';

// Material
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { LandingComponent } from './landing.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [LandingComponent],
  imports: [
    RouterModule,
    MatIconModule,

    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
  ],
  providers: [],
  bootstrap: [LandingComponent]
})
export class LandingModule { }
