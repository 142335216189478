import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';
import { SharedService } from '../services/shared.service';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, AfterViewInit {

  @ViewChild('videoEl') videoEl!: ElementRef;

  private apiStreamingVideo = environment.apiStreamingVideo;

  public videoSource: String = `${this.apiStreamingVideo}common/video/video_landing_hd.mp4`;
  public videoSource2: String = `${this.apiStreamingVideo}common/video/video_landing_ecommerce_1.mp4`;
  public videoSource3: String = `${this.apiStreamingVideo}common/video/video_landing_faccedainniovazione_0.mp4`;
  public videoSource4: String = `${this.apiStreamingVideo}common/video/video_landing_macelleria_garbossa_clip_1.mp4`;
  public videoSource5: String = `${this.apiStreamingVideo}common/video/video_landing_gioielleria.mp4`;

  CONFIG: any;

  public selectedPagina!: string;
  public webSites: Array<String> = ["TORINO", "CUNEO"];

  constructor(
    private viewportScroller: ViewportScroller,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
      this.CONFIG = this.sharedService.getConfiguration();
  }

  ngAfterViewInit(): void {
    this.videoEl.nativeElement.pause();
    this.videoEl.nativeElement.muted = true;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.videoSource = `${this.apiStreamingVideo}common/video/video_landing_bassa_risoluzione.mp4`;
      this.videoSource2 = `${this.apiStreamingVideo}common/video/video_landing_ecommerce_1.mp4`;
      this.videoSource3 = `${this.apiStreamingVideo}common/video/video_landing_faccedainniovazione_0.mp4`;
      this.videoSource4 = `${this.apiStreamingVideo}common/video/video_landing_macelleria_garbossa_clip_1.mp4`;
      this.videoSource5 = `${this.apiStreamingVideo}common/video/video_landing_gioielleria.mp4`;
    }
  }

  // NB
  // this has been done due to a browser restriction
  // play() failed because the user didn't interact with the document first
  private async playVideo() {
    try {
      await this.videoEl.nativeElement.play();
    } catch (error) {
      setTimeout(() => {
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
          this.playVideo();
      }, 5000);
    }
  }

  public onClick(elementId: string): void { this.viewportScroller.scrollToAnchor(elementId); }

  public redirectToLogin(): void {

    if (!this.selectedPagina) return;

    window.location.href = `${environment.apiUrl}/${this.selectedPagina}/`;
  }

}
